import {
    Alert,
    Button,
    ColumnLayout,
    Container,
    ContentLayout,
    CopyToClipboard,
    Header,
    SpaceBetween,
    Spinner,
    Table,
} from "@cloudscape-design/components";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useParams } from "react-router-dom";
import {
    useAddOverrideMutation,
    useDeleteFutureStateMutation,
    useDeleteOverrideMutation,
    useGetFeatureFlagQuery,
    useUpdateAllocationsMutation,
} from "../../../api/effApi";
import { FeatureFlagState } from "../../../types/FeatureFlagState";
import { Treatment } from "../../../types/Treatment";
import { getDisplayTimeDifference } from "../../../util/timeUtil";
import AllocationsBar from "../../common/AllocationsBar";
import Debug from "../../common/Debug";
import ValueWithLabel from "../../common/ValueWithLabel";
import AddOverrideModal from "./AddOverrideModal";
import DeleteFutureStateConfirmationModal from "./DeleteFutureStateConfirmationModal";
import ModifyAllocationsModal from "./ModifyAllocationsModal";
import orderStates, { OrderedStates } from "./orderStates";

const FeatureFlagEnvDetailPage: FunctionComponent = () => {
    const params = useParams();
    const ffId = params.id!;
    const envId = params.envId!;
    const user = useAuth().user!.profile.sub;
    const { data, isLoading, refetch, isFetching } = useGetFeatureFlagQuery(ffId);
    const [updateAllocations] = useUpdateAllocationsMutation();
    const [deleteFutureState] = useDeleteFutureStateMutation();
    const [addOverride] = useAddOverrideMutation();
    const [deleteOverride, { isLoading: isDeletingOverride, originalArgs: deleteOverrideArgs }] =
        useDeleteOverrideMutation();
    const [isModifyAllocationsModalOpen, setIsModifyAllocationsModalOpen] = useState(false);
    const [isAddOverrideModalOpen, setIsAddOverrideModalOpen] = useState(false);
    const [futureStateForDeletion, setFutureStateForDeletion] = useState<FeatureFlagState | null>(null);
    const [orderedStates, setOrderedStates] = useState<OrderedStates | null>(null);

    useEffect(() => {
        const updateOrderedStates = () => {
            if (data?.featureFlag?.environments?.[envId]?.states) {
                setOrderedStates(orderStates(data.featureFlag.environments[envId].states));
            }
        };
        const interval = setInterval(updateOrderedStates, 1000);
        updateOrderedStates();

        return () => clearInterval(interval);
    }, [data, envId]);

    if (isLoading || !data || !orderedStates) {
        return <Spinner />;
    }

    if (!data.featureFlag?.environments?.[envId]) {
        return (
            <Alert type="error">
                Env ({envId}) not found for feature flag {ffId}
            </Alert>
        );
    }

    const featureFlag = data.featureFlag;
    const env = data.featureFlag.environments[envId];

    const hasOperatorAccess = featureFlag.owners.includes(user) || featureFlag.operators.includes(user);

    return (
        <ContentLayout
            header={
                <Header variant="h1">
                    {featureFlag.displayName} - {env.displayName}
                </Header>
            }
        >
            <SpaceBetween size="l">
                {!hasOperatorAccess && <Alert type="info">You do not have access to modify this environment</Alert>}

                <Container
                    header={
                        <Header
                            actions={<Button onClick={() => refetch()} loading={isFetching} iconName="refresh" />}
                            variant="h2"
                        >
                            General Information
                        </Header>
                    }
                >
                    <ColumnLayout columns={3}>
                        <ValueWithLabel label="ID">
                            <CopyToClipboard
                                textToCopy={envId}
                                copySuccessText={`Copied "${envId}"`}
                                copyErrorText="Failed"
                                variant="inline"
                            />
                        </ValueWithLabel>
                        <ValueWithLabel label="Created By">{env.createdBy}</ValueWithLabel>
                        <ValueWithLabel label="Created">{new Date(env.createdTime).toLocaleString()}</ValueWithLabel>
                    </ColumnLayout>
                </Container>

                {!orderedStates.currentState && (
                    <Alert type="warning">There is no active state for this feature flag environment yet</Alert>
                )}

                {orderedStates.currentState && (
                    <>
                        <Container
                            header={
                                <Header
                                    variant="h2"
                                    actions={
                                        <Button
                                            onClick={() => setIsModifyAllocationsModalOpen(true)}
                                            disabled={!hasOperatorAccess}
                                            variant="primary"
                                        >
                                            Modify
                                        </Button>
                                    }
                                >
                                    Dialup Percentage
                                </Header>
                            }
                            data-testid="current-state-container"
                        >
                            <AllocationsBar allocations={orderedStates.currentState.allocations} />
                        </Container>
                    </>
                )}

                {hasOperatorAccess && (
                    <Table
                        header={
                            <Header
                                variant="h2"
                                counter={`(${env.overridesCount})`}
                                actions={<Button onClick={() => setIsAddOverrideModalOpen(true)}>Add</Button>}
                            >
                                Overrides
                            </Header>
                        }
                        items={env.overrides ?? []}
                        columnDefinitions={[
                            { header: "Discriminator", cell: override => override.discriminator },
                            {
                                header: "Treatment",
                                cell: override => (override.treatment === Treatment.T1 ? "On" : "Off"),
                            },
                            { header: "Created By", cell: override => override.createdBy },
                            {
                                header: "Created Time",
                                cell: override => new Date(override.createdTime).toLocaleString(),
                            },
                            {
                                header: "Actions",
                                cell: override => (
                                    <Button
                                        variant="inline-icon"
                                        onClick={async () => {
                                            await deleteOverride({
                                                ffId,
                                                envId,
                                                discriminator: override.discriminator,
                                            });
                                            // TODO handle error
                                        }}
                                        loading={
                                            isDeletingOverride &&
                                            deleteOverrideArgs?.discriminator === override.discriminator
                                        }
                                        disabled={isDeletingOverride}
                                        iconName="remove"
                                    />
                                ),
                            },
                        ]}
                        empty={<Alert type="info">No overrides to display</Alert>}
                        trackBy="discriminator"
                        variant="container"
                        data-testid="overrides-container"
                    />
                )}
                {!hasOperatorAccess && (
                    <Container
                        header={
                            <Header counter={`(${env.overridesCount})`} variant="h2">
                                Overrides
                            </Header>
                        }
                        data-testid="overrides-container"
                    >
                        <Alert type="info">You do not have access to view overrides</Alert>
                    </Container>
                )}

                {orderedStates.futureStates.length > 0 && (
                    <Table
                        header={<Header variant="h2">Future</Header>}
                        items={orderedStates.futureStates}
                        columnDefinitions={[
                            {
                                header: "Activation Time",
                                cell: state => new Date(state.activationTime).toLocaleString(),
                            },
                            {
                                header: "Time To Activation",
                                cell: state => {
                                    return getDisplayTimeDifference(new Date(state.activationTime));
                                },
                            },
                            {
                                header: "Dialup Percentage",
                                cell: state => <AllocationsBar allocations={state.allocations} />,
                            },
                            { header: "Updated By", cell: state => state.createdBy },
                            ...(hasOperatorAccess
                                ? [
                                      {
                                          header: "Actions",
                                          cell: (state: FeatureFlagState) => (
                                              <Button
                                                  variant="inline-icon"
                                                  onClick={() => setFutureStateForDeletion(state)}
                                                  iconName="remove"
                                                  disabled={
                                                      new Date(state.activationTime).getTime() - Date.now() <
                                                      3 * 60 * 1000
                                                  } // disable if activation time is in the next 3 minutes
                                              />
                                          ),
                                      },
                                  ]
                                : []),
                        ]}
                        empty={<Alert type="info">No future changes to display</Alert>}
                        trackBy="activationTime"
                        variant="container"
                        data-testid="future-states-container"
                    />
                )}

                <Table
                    header={<Header variant="h2">History</Header>}
                    items={[
                        ...(orderedStates.currentState ? [orderedStates.currentState] : ([] as FeatureFlagState[])),
                        ...orderedStates.pastStates.toReversed(),
                    ]}
                    columnDefinitions={[
                        { header: "Activation Time", cell: state => new Date(state.activationTime).toLocaleString() },
                        {
                            header: "Dialup Percentage",
                            cell: state => <AllocationsBar allocations={state.allocations} />,
                        },
                        { header: "Updated By", cell: state => state.createdBy },
                    ]}
                    empty={<Alert type="info">No history to display</Alert>}
                    trackBy="activationTime"
                    variant="container"
                    data-testid="history-container"
                />

                <Debug value={env} />
            </SpaceBetween>

            {isModifyAllocationsModalOpen && (
                <ModifyAllocationsModal
                    allocations={orderedStates.currentState?.allocations}
                    onClose={() => setIsModifyAllocationsModalOpen(false)}
                    onSave={async allocations => {
                        await updateAllocations({
                            ffId,
                            envId,
                            allocations,
                        });
                        // TODO handle error
                        setIsModifyAllocationsModalOpen(false);
                    }}
                />
            )}

            {isAddOverrideModalOpen && (
                <AddOverrideModal
                    existingOverrides={env.overrides}
                    onClose={() => setIsAddOverrideModalOpen(false)}
                    onSave={async override => {
                        await addOverride({
                            ffId,
                            envId,
                            ...override,
                        });
                        // TODO handle error
                        setIsAddOverrideModalOpen(false);
                    }}
                />
            )}

            {futureStateForDeletion && (
                <DeleteFutureStateConfirmationModal
                    stateToDelete={futureStateForDeletion}
                    onClose={() => setFutureStateForDeletion(null)}
                    onDelete={async () => {
                        await deleteFutureState({
                            ffId,
                            envId,
                            activationTime: futureStateForDeletion.activationTime,
                        });
                        setFutureStateForDeletion(null);
                        // TODO handle error
                    }}
                />
            )}
        </ContentLayout>
    );
};

export default FeatureFlagEnvDetailPage;
