import { AppLayout, Input, TopNavigation } from "@cloudscape-design/components";
import { FunctionComponent, useState } from "react";
import { useAuth } from "react-oidc-context";
import { Outlet, useNavigate } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";

const Root: FunctionComponent = () => {
    const auth = useAuth();
    const [search, setSearch] = useState("");
    const navigate = useNavigate();

    return (
        <>
            <TopNavigation
                identity={{
                    href: "/",
                    title: "Enterprise Feature Flags",
                    logo: {
                        src: "/logo.png",
                    },
                    onFollow: e => {
                        e.preventDefault();
                        navigate("/");
                    },
                }}
                search={
                    <Input
                        type="search"
                        placeholder="Search"
                        value={search}
                        onChange={e => setSearch(e.detail.value)}
                        onKeyDown={e => {
                            if (e.detail.key === "Enter") {
                                navigate(`/feature-flags/${search}`);
                            }
                        }}
                    />
                }
                utilities={[
                    {
                        type: "button",
                        text: "Create Feature Flag",
                        href: "/feature-flags/create",
                        onFollow: e => {
                            e.preventDefault();
                            navigate(e.detail.href!);
                        },
                    },
                    {
                        type: "menu-dropdown",
                        iconName: "user-profile",
                        text: auth.user?.profile.sub,
                        items: [
                            {
                                text: "Sign out",
                                id: "sign-out",
                            },
                        ],
                        onItemClick: e => {
                            if (e.detail.id === "sign-out") {
                                auth.signoutRedirect();
                            }
                        },
                    },
                ]}
            />
            <AppLayout toolsHide={true} navigationHide={true} breadcrumbs={<Breadcrumbs />} content={<Outlet />} />
        </>
    );
};

export default Root;
