import { UpdatePermissionsRequest } from "../types/api/UpdatePermissionsRequest";

export type FeatureFlagPermissions = Pick<UpdatePermissionsRequest, "owners" | "operators">;

export type FeatureFlagPermissionsErrors = {
    owners?: string;
    operators?: string;
};

export function validatePermissions({ owners, operators }: FeatureFlagPermissions) {
    const errors: FeatureFlagPermissionsErrors = {};

    if (!owners.length) {
        errors.owners = "At least 1 owner is required";
    } else if (owners.some(owner => !owner.match(/^[a-z0-9_]+$/))) {
        errors.owners = "Owners must be lower case alphanumeric with underscores";
    } else if (new Set(owners).size < owners.length) {
        errors.owners = "Duplicate owners are not allowed";
    }

    if (operators.some(operator => !operator.match(/^[a-z0-9_]+$/))) {
        errors.operators = "Operators must be lower case alphanumeric with underscores";
    } else if (new Set(operators).size < operators.length) {
        errors.operators = "Duplicate operators are not allowed";
    } else if (new Set(operators.concat(owners)).size < owners.length + operators.length) {
        errors.operators = "Owners cannot be operators";
    }

    return errors;
}
