import { Alert, Spinner } from "@cloudscape-design/components";
import React, { FunctionComponent, useEffect } from "react";
import { AuthProvider, AuthProviderProps, useAuth } from "react-oidc-context";
import {
    SERVICEAUTH_AUTHORITY,
    SERVICEAUTH_CLIENT_ID,
    SERVICEAUTH_REDIRECT_URI,
    SERVICEAUTH_SCOPE,
} from "../constants";
import { addShamefulSplitPolyfill } from "../util/shamefulSplitPolyfillUtil";

const oidcConfig: AuthProviderProps = {
    authority: SERVICEAUTH_AUTHORITY,
    client_id: SERVICEAUTH_CLIENT_ID,
    redirect_uri: SERVICEAUTH_REDIRECT_URI,
    response_type: "code",
    scope: `openid ${SERVICEAUTH_SCOPE}`,
    metadata: {
        authorization_endpoint: "https://weblogin.asu.edu/serviceauth/oauth2/code/allow",
        token_endpoint: "https://weblogin.asu.edu/serviceauth/oauth2/token",
        code_challenge_methods_supported: ["S256"],
        userinfo_endpoint: "https://weblogin.asu.edu/serviceauth/oauth2/oidc/user-info",
        end_session_endpoint: "https://weblogin.asu.edu/cas/logout",
    },
    onSigninCallback: () => {
        window.history.replaceState({}, "", window.location.pathname);
    },
};

export type AuthProps = React.PropsWithChildren;

const Auth: FunctionComponent<AuthProps> = props => {
    addShamefulSplitPolyfill();

    return (
        <AuthProvider {...oidcConfig}>
            <AuthHandler>{props.children}</AuthHandler>
        </AuthProvider>
    );
};

type AuthHandlerProps = React.PropsWithChildren;

const AuthHandler: FunctionComponent<AuthHandlerProps> = props => {
    const auth = useAuth();

    useEffect(() => {
        if (!auth.isLoading && !auth.isAuthenticated) {
            auth.signinRedirect();
        }
    }, [auth]);

    if (auth.isLoading) {
        return <Spinner size="large" />;
    }

    if (auth.error) {
        return <Alert type="error">Auth error... {auth.error.message}</Alert>;
    }

    return <>{props.children}</>;
};

export default Auth;
