import {
    Alert,
    Button,
    ColumnLayout,
    Container,
    ContentLayout,
    CopyToClipboard,
    Header,
    SpaceBetween,
    Spinner,
    StatusIndicator,
    Table,
} from "@cloudscape-design/components";
import React, { FunctionComponent, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useParams } from "react-router-dom";
import { useGetFeatureFlagQuery, useUpdatePermissionsMutation } from "../../../api/effApi";
import Debug from "../../common/Debug";
import Link from "../../common/Link";
import ValueWithLabel from "../../common/ValueWithLabel";
import ModifyPermissionsModal from "./ModifyPermissionsModal";

const FeatureFlagDetailPage: FunctionComponent = () => {
    const params = useParams();
    const id = params.id!;
    const user = useAuth().user!.profile.sub;
    const { data, isLoading, refetch, isFetching } = useGetFeatureFlagQuery(id);
    const [updatePermissions] = useUpdatePermissionsMutation();
    const [isModifyPermissionsModalOpen, setIsModifyPermissionsModalOpen] = useState(false);

    if (isLoading || !data) {
        return <Spinner />;
    }

    if (!data.featureFlag) {
        return <Alert type="error">Feature flag ({id}) not found</Alert>;
    }

    const featureFlag = data.featureFlag;

    const hasOwnerPermissions = featureFlag.owners.includes(user);

    return (
        <ContentLayout header={<Header variant="h1">{featureFlag.displayName}</Header>}>
            <SpaceBetween size="l">
                <Container
                    header={
                        <Header
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button onClick={() => refetch()} loading={isFetching} iconName="refresh" />
                                    {hasOwnerPermissions ? (
                                        <Button onClick={() => setIsModifyPermissionsModalOpen(true)}>
                                            Modify Permissions
                                        </Button>
                                    ) : null}
                                </SpaceBetween>
                            }
                            variant="h2"
                        >
                            General Information
                        </Header>
                    }
                >
                    <ColumnLayout columns={4}>
                        <ValueWithLabel label="ID">
                            <CopyToClipboard
                                textToCopy={featureFlag.id}
                                copySuccessText={`Copied "${featureFlag.id}"`}
                                copyErrorText="Failed"
                                variant="inline"
                            />
                        </ValueWithLabel>
                        <ValueWithLabel label="Description">{featureFlag.description}</ValueWithLabel>
                        <ValueWithLabel label="Owners">{featureFlag.owners.join(", ")}</ValueWithLabel>
                        <ValueWithLabel label="Operators">
                            {featureFlag.operators.length > 0 && featureFlag.operators.join(", ")}
                            {featureFlag.operators.length === 0 && (
                                <StatusIndicator type="stopped">None</StatusIndicator>
                            )}
                        </ValueWithLabel>
                        <ValueWithLabel label="Created By">{featureFlag.createdBy}</ValueWithLabel>
                        <ValueWithLabel label="Created">
                            {new Date(featureFlag.createdTime).toLocaleString()}
                        </ValueWithLabel>
                    </ColumnLayout>
                </Container>

                <Table
                    header={<Header variant="h2">Environments</Header>}
                    items={Object.entries(featureFlag.environments).map(([envId, env]) => ({
                        ...env,
                        id: envId,
                    }))}
                    columnDefinitions={[
                        {
                            header: "Name",
                            cell: env => <Link to={`/feature-flags/${id}/env/${env.id}`}>{env.displayName}</Link>,
                        },
                    ]}
                    trackBy="id"
                    variant="container"
                />

                <Debug value={data} />
            </SpaceBetween>

            {isModifyPermissionsModalOpen && (
                <ModifyPermissionsModal
                    currentPermissions={{ owners: featureFlag.owners, operators: featureFlag.operators }}
                    onClose={() => setIsModifyPermissionsModalOpen(false)}
                    onSave={async permissions => {
                        await updatePermissions({ ffId: id, ...permissions });
                        // TODO handle error
                        setIsModifyPermissionsModalOpen(false);
                    }}
                />
            )}
        </ContentLayout>
    );
};

export default FeatureFlagDetailPage;
