import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../constants";
import { AddOverrideRequest } from "../types/api/AddOverrideRequest";
import { CreateFeatureFlagRequest } from "../types/api/CreateFeatureFlagRequest";
import { CreateFeatureFlagResponse } from "../types/api/CreateFeatureFlagResponse";
import { DeleteFutureStateRequest } from "../types/api/DeleteFutureStateRequest";
import { DeleteOverrideRequest } from "../types/api/DeleteOverrideRequest";
import { GetFeatureFlagResponse } from "../types/api/GetFeatureFlagResponse";
import { ListFeatureFlagsRequest } from "../types/api/ListFeatureFlagsRequest";
import { ListFeatureFlagsResponse } from "../types/api/ListFeatureFlagsResponse";
import { UpdateAllocationsRequest } from "../types/api/UpdateAllocationsRequest";
import { UpdatePermissionsRequest } from "../types/api/UpdatePermissionsRequest";
import { getAuthUser } from "../util/authUtil";

const effApi = createApi({
    reducerPath: "effApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL,
        prepareHeaders: headers => {
            headers.set("Authorization", `Bearer ${getAuthUser()?.access_token}`);
            return headers;
        },
    }),
    tagTypes: ["FeatureFlag"],
    endpoints: builder => ({
        listFeatureFlags: builder.query<ListFeatureFlagsResponse, ListFeatureFlagsRequest>({
            query: (request: ListFeatureFlagsRequest) => {
                return {
                    url: "feature-flags",
                    params: {
                        ...request,
                    },
                };
            },
            providesTags: ["FeatureFlag"],
        }),

        getFeatureFlag: builder.query<GetFeatureFlagResponse, string>({
            query: (id: string) => {
                return {
                    url: `feature-flags/${id}`,
                };
            },
            providesTags: ["FeatureFlag"],
        }),

        createFeatureFlag: builder.mutation<CreateFeatureFlagResponse, CreateFeatureFlagRequest>({
            query: (request: CreateFeatureFlagRequest) => {
                return {
                    url: "feature-flags",
                    method: "POST",
                    body: request,
                };
            },
            invalidatesTags: ["FeatureFlag"],
        }),

        updateAllocations: builder.mutation<null, UpdateAllocationsRequest>({
            query: (request: UpdateAllocationsRequest) => {
                throw new Error("Function not implemented.");
            },
            invalidatesTags: ["FeatureFlag"],
        }),

        deleteFutureState: builder.mutation<null, DeleteFutureStateRequest>({
            query: (request: DeleteFutureStateRequest) => {
                throw new Error("Function not implemented.");
            },
            invalidatesTags: ["FeatureFlag"],
        }),

        addOverride: builder.mutation<null, AddOverrideRequest>({
            query: (request: AddOverrideRequest) => {
                throw new Error("Function not implemented.");
            },
            invalidatesTags: ["FeatureFlag"],
        }),

        deleteOverride: builder.mutation<null, DeleteOverrideRequest>({
            query: (request: DeleteOverrideRequest) => {
                throw new Error("Function not implemented.");
            },
            invalidatesTags: ["FeatureFlag"],
        }),

        updatePermissions: builder.mutation<null, UpdatePermissionsRequest>({
            query: (request: UpdatePermissionsRequest) => {
                throw new Error("Function not implemented.");
            },
            invalidatesTags: ["FeatureFlag"],
        }),
    }),
});

export { effApi };

export const {
    useListFeatureFlagsQuery,
    useGetFeatureFlagQuery,
    useCreateFeatureFlagMutation,
    useUpdateAllocationsMutation,
    useDeleteFutureStateMutation,
    useAddOverrideMutation,
    useDeleteOverrideMutation,
    useUpdatePermissionsMutation,
} = effApi;
