import { Box, Button, FormField, Header, Input, Modal, SpaceBetween } from "@cloudscape-design/components";
import { FunctionComponent, useState } from "react";

export type ModifyAllocationsModalProps = {
    allocations?: Partial<Record<string, number>>;
    onClose: () => void;
    onSave: (allocations: Partial<Record<string, number>>) => Promise<void>;
};

const ModifyAllocationsModal: FunctionComponent<ModifyAllocationsModalProps> = props => {
    const { allocations, onClose, onSave } = props;

    const [dialupPercentageStr, setDialupPercentageStr] = useState((allocations?.T1 ?? 0) + "");
    const [dialupPercentageError, setDialupPercentageError] = useState("");
    const [isSaving, setIsSaving] = useState(false);

    const handleDialupPercentageChange = (input: string) => {
        const cleanedInput = input.replace(/[^0-9]/g, "");
        setDialupPercentageStr(cleanedInput);

        const dialupPercentage = parseInt(input);
        if (dialupPercentage < 0 || dialupPercentage > 100) {
            setDialupPercentageError("Must be inclusively between 0 and 100");
        } else {
            setDialupPercentageError("");
        }
    };

    const handleSave = async () => {
        if (!dialupPercentageStr) {
            setDialupPercentageError("Required");
            return;
        }

        const dialupPercentage = parseInt(dialupPercentageStr);

        setIsSaving(true);
        await onSave({ C: 100 - dialupPercentage, T1: dialupPercentage });
        setIsSaving(false);
    };

    return (
        <Modal
            visible={true}
            header={<Header>Modify Allocations</Header>}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => onClose()}>Cancel</Button>
                        <Button
                            onClick={() => handleSave()}
                            loading={isSaving}
                            disabled={!!dialupPercentageError}
                            variant="primary"
                        >
                            Save
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            onDismiss={() => onClose()}
            size="medium"
        >
            <SpaceBetween size="m">
                <FormField
                    label="Dialup Percentage"
                    errorText={dialupPercentageError}
                    constraintText="Must be an integer inclusively between 0 and 100"
                >
                    <Input
                        value={dialupPercentageStr + ""}
                        type="number"
                        onChange={e => handleDialupPercentageChange(e.detail.value)}
                    />
                </FormField>

                <SpaceBetween direction="horizontal" size="xs">
                    <Button onClick={() => handleDialupPercentageChange("0")}>0%</Button>
                    <Button onClick={() => handleDialupPercentageChange("1")}>1%</Button>
                    <Button onClick={() => handleDialupPercentageChange("5")}>5%</Button>
                    <Button onClick={() => handleDialupPercentageChange("10")}>10%</Button>
                    <Button onClick={() => handleDialupPercentageChange("25")}>25%</Button>
                    <Button onClick={() => handleDialupPercentageChange("50")}>50%</Button>
                    <Button onClick={() => handleDialupPercentageChange("100")}>100%</Button>
                </SpaceBetween>
            </SpaceBetween>
        </Modal>
    );
};

export default ModifyAllocationsModal;
